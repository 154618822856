import { iConfig } from '@/config/config-models';

export const config: iConfig = {
	apiBaseUrl: 'https://api-dev.penncobalt.com/',
	providerManagementFeature: false,
	downForMaintenance: false,

	gaTrackingId: undefined,
	ga4MeasurementId: undefined,
	mixPanelId: 'b4b40fb252c0841451135df33b2f1b17',

	showDebug: false,

	localhostSubdomain: undefined,

	authRedirectUrls: [],
	storageKeys: {
		mhicRecentOrdersStorageKey: 'mhicRecentOrders',
	},
};

export default config;
